import axiosCodeigniter from "../api/axiosCodeigniter"
import { AxiosResponse } from "axios"
import { BOUGHT_PLANS_URL } from "../utils/constants"

export async function PlansBoughtService(): Promise<AxiosResponse> {
  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const response = await axiosCodeigniter.get(`${BOUGHT_PLANS_URL}`, headers)
  return response
}
